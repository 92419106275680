.survey-card {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  -webkit-box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 40px -5px rgba(0, 0, 0, 0.75);
}
.survey-bg {
  background-color: aliceblue;
}

.survey-text {
  color: white;
}
